import { observable } from 'mobx';

export default class Day {
  date = '';

  created_at: Date = new Date();

  updated_at: Date = new Date();

  @observable content = '';

  static create(date: string): Day {
    const day = new Day();
    day.date = date;
    return day;
  }
}
