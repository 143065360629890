import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved
import { editor as ed } from 'monaco-editor';
import Editor from '@monaco-editor/react';
import store from '../lib/Store';
import { Props, withStores } from '../lib/types';

import IStandaloneCodeEditor = ed.IStandaloneCodeEditor;


@inject('store') @observer
class EditorWrapper extends React.Component<Props> {
  editor: IStandaloneCodeEditor;

  didMount = (getEditorValue: () => string, editor: IStandaloneCodeEditor) => {
    this.editor = editor;
    const { store: { day } } = this.props;

    // editor.focus();
    editor.onKeyDown((e) => {
      const time = moment().format('HH:mm');
      if (e.code === 'Enter') {
        editor.trigger('keyboard', 'type', { text: `\n[${time}] - ` });
        e.preventDefault();
      }

      if (!editor.getValue()) {
        editor.trigger('keyboard', 'type', { text: `[${time}] - ` });
      }
    });

    let shouldTrigger = true;

    editor.onDidChangeModelContent(() => {
      if (shouldTrigger) {
        store.syncDay({ date: day.date, content: editor.getValue() });
      }
    });

    store.onNewValue((value) => {
      try {
        shouldTrigger = false;
        editor.setValue(value);
        const actualLineCount = editor.getModel().getLineCount();
        const actualLineColumn = editor.getModel().getLineMaxColumn(actualLineCount);
        editor.setPosition({ lineNumber: actualLineCount, column: actualLineColumn });
      } finally {
        shouldTrigger = true;
      }
    });
  };

  onResize = () => {
    console.log('resize');
    if (this.editor) {
      this.editor.layout();
    }
  };

  render(): React.ReactNode {
    const { store: { day } } = this.props;

    console.log('render');

    if (this.editor) {
      this.editor.focus();
    }

    const options: ed.IEditorConstructionOptions = {
      wordWrap: 'on',
      quickSuggestions: false,
      lineNumbers: 'off',
      minimap: {
        enabled: false,
      },
      // selectionHighlight: false,
      copyWithSyntaxHighlighting: false,
    };

    // @ts-ignore
    return (
      <div className="editor">
        <div className="date">{day.date}</div>
        <Editor
          language="markdown"
          theme="vs-dark"
          value={day.content}
          editorDidMount={this.didMount}
          options={options}
          loading="Loading..."
        />
      </div>
    );
  }
}

export default withStores('store')(EditorWrapper);
