import axios from 'axios';

const api = axios.create({
  baseURL: '/api/',
});

export default {
  getDaysTimestamps: async () => (await api.get('days/timestamps')).data,
  download: async dates => (await api.get('days/download', {
    params: { dates },
  })).data,
  upload: async data => (await api.post('days/upload', data)).data,
};
