import React, { KeyboardEvent } from 'react';
import cs from 'classnames';
import { inject, observer } from 'mobx-react';
import './LeftPanel.scss';
import moment from 'moment';
import {Props, withStores} from '../lib/types';


interface State {
  showForm: boolean;
  dayValue: string;
}

@inject('store') @observer
class LeftPanel extends React.Component<Props, State> {
  state = {
    showForm: false,
    dayValue: '',
  };

  onClick = (day) => (): void => {
    const { store } = this.props;
    store.changeDay(day);
  };

  toggleForm = (): void => {
    this.setState(({ showForm }) => ({ showForm: !showForm }));
  };

  onChange = (e): void => {
    this.setState({ dayValue: e.target.value });
  };

  onKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    const { store } = this.props;
    const target = e.target as HTMLInputElement;
    if (e.keyCode === 13 && target.value.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
      store.createDay(target.value);
      this.toggleForm();
    }
  };

  render(): React.ReactNode {
    const { store: { days, day } } = this.props;
    const { showForm, dayValue } = this.state;
    const sort = (a, b) => (moment(a, 'DD/MM/YYYY').isBefore(moment(b, 'DD/MM/YYYY')) ? 1 : -1);
    const sortedDays = Object.keys(days).sort(sort);

    return (
      <div className="left-panel">
        {/*<input type="text" placeholder="Search..." />*/}
        {sortedDays.map((date) => (
          <div key={date} className={cs('date', { active: day.date === date })} onClick={this.onClick(date)}>
            <div>{date}</div>
          </div>
        ))}
        {showForm && (
          <div className="date">
            <input type="text" value={dayValue} onChange={this.onChange} onKeyDown={this.onKeyDown} onBlur={this.toggleForm} autoFocus />
          </div>
        )}
        <button type="button" className="add" onClick={this.toggleForm}>add</button>
      </div>
    );
  }
}

export default withStores('store')(LeftPanel);
