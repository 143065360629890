import { createConsumer, Cable, Channel } from '@rails/actioncable';
import { observable, action, computed } from 'mobx';
import moment from 'moment';
import { keyBy, first } from 'lodash';
import { randomstring } from './functions';
import Day from '../models/Day';
import api from './api';


export class Store {
  @observable day: Day | null = null;

  @observable days: Day[] = [];

  cable!: Cable;

  editorChannel!: Channel;

  sessionId: string;

  private onNewValueHandler: (value: string) => void;

  init() {
    this.cable = createConsumer(`ws://${window.location.host}/cable`);

    this.sessionId = randomstring(8);

    this.editorChannel = this.cable.subscriptions.create({ channel: 'EditorChannel', sessionId: this.sessionId }, {
      received: this.onReceivedData,
    });
  }

  onNewValue(handler: (value: string) => void) {
    this.onNewValueHandler = handler;
  }

  @action.bound async onReceivedData({ sessionId, data }) {
    if (sessionId === this.sessionId) {
      return;
    }

    if (this.day.date === data.date && this.onNewValueHandler) {
      this.onNewValueHandler(data.content);
    }
  }

  @action async syncData() {
    const serverData = await api.getDaysTimestamps();
    // eslint-disable-next-line no-return-assign
    serverData.map(d => d.date).sort().reverse().map(d => this.days[d] = null);
    const today = moment().format('DD/MM/YYYY');

    let day;
    if (typeof this.days[today] !== 'undefined') {
      day = first(await api.download(today));
    } else {
      day = Day.create(today);
      this.syncDay(day);
    }
    this.days[today] = day;
    this.day = day;
  }

  @action createDay(date) {
    this.days[date] = Day.create(date);
    this.syncDay(this.days[date]);
    this.day = this.days[date];
  }

  syncDay(data) {
    this.editorChannel.send({ ...data });
  }

  @action async changeDay(date) {
    // eslint-disable-next-line no-multi-assign,prefer-destructuring
    const day = (await api.download(date))[0];
    this.days[date] = day;
    this.day = day;
  }

  @computed get daysMap(): {[key: string]: Day} {
    return keyBy(this.days, 'date');
  }
}

export default new Store();
