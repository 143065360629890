import 'react-hot-loader';
import { hot } from 'react-hot-loader/root';
import React, { Component } from 'react';
import { Provider } from 'mobx-react';
import './App.scss';
import store from '../lib/Store';
import LeftPanel from './LeftPanel';
import EditorWrapper from './EditorWrapper';


@hot export default class App extends Component {
  state = {
    loaded: false,
  };

  async componentDidMount() {
    await store.init();
    await store.syncData();

    // @ts-ignore
    window.store = store;

    this.setState({ loaded: true });
  }

  render() {
    const { loaded } = this.state;

    if (!loaded) {
      return <div>Loading</div>;
    }

    return (
      <Provider store={store}>
        <div className="container">
          <LeftPanel />
          <EditorWrapper />
        </div>
      </Provider>
    );
  }
}
