import {inject, IWrappedComponent} from 'mobx-react';
// import {NavigationInjectedProps, NavigationParams, NavigationScreenProp, NavigationState} from 'react-navigation';
import {Store} from './Store';

// export type Navigation<T = NavigationParams> = NavigationScreenProp<NavigationState, T>;

export interface BaseProps<T = {}> {
    store: Store,
    // navigation: Navigation<T>
}

export type Props<T = {}> = BaseProps<T>;// & NavigationInjectedProps;


// const bootstrap = () => ({ store: Store });

export interface StoresType { store: Store }

export type Subtract<T, K> = Omit<T, keyof K>;

export const withStores = <TStoreProps extends keyof StoresType>(...stores: TStoreProps[]) =>
    <TProps extends Pick<StoresType, TStoreProps>>(component: React.ComponentType<TProps>) => {
        return (inject(...stores)(component) as any) as
            React.FC<Subtract<TProps, Pick<StoresType, TStoreProps>> &
                Partial<Pick<StoresType, TStoreProps>>> &
            IWrappedComponent<TProps>;
    };
